import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Full Snatch 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 17.3`}</strong></p>
    <p>{`Prior to 8:00, complete:\\
3 rounds of:\\
6 chest-to-bar pull-ups\\
6 squat snatches, 95 lb/65lb\\
Then, 3 rounds of:\\
7 chest-to-bar pull-ups\\
5 squat snatches, 135 lb/95lb\\
`}{`*`}{`Prior to 12:00, complete 3 rounds of:\\
8 chest-to-bar pull-ups\\
4 squat snatches, 185 lb/135lb\\
`}{`*`}{`Prior to 16:00, complete 3 rounds of:\\
9 chest-to-bar pull-ups\\
3 squat snatches, 225 lb/155lb\\
`}{`*`}{`Prior to 20:00, complete 3 rounds of:\\
10 chest-to-bar pull-ups\\
2 squat snatches, 245 lb/175lb\\
Prior to 24:00, complete 3 rounds of:\\
11 chest-to-bar pull-ups\\
1 squat snatch 265 lb/185lb`}</p>
    <p>{`*`}{`If all reps are completed, time cap extends by 4 minutes.`}</p>
    <p><strong parentName="p">{`*`}{`Judging for 17.3 will also take place from 11:30-12:30 before class
for those who aren’t unlimited members.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      